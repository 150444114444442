:root {
  //font font-family
  --q-font-family: 'Roboto', sans-serif;
  --q-secondary-font-family: 'Montserrat', sans-serif;

  // heading font size
  --q-font-size-h1: 36px;
  --q-font-size-h2: 30px;
  --q-font-size-h3: 24px;
  --q-font-size-h4: 18px;
  --q-font-size-h5: 16px;

  // font-weight
  --q-font-weight-light: 300;
  --q-font-weight-medium: 500;
  --q-font-weight-regular: 400;
  --q-font-weight-bold: 700;
  --q-font-weight-large:600;

  //body text
  --q-xxlarge-48-font-size:48px;
  --q-xlarge-24-font-size:24px;
  --q-large-20-font-size:20px;
  --q-medium-16-font-size:16px;
  --q-small-14-font-size:14px;
  --q-small-13-font-size:13px;
  --q-xsmall-12-font-size:12px;
  --q-xsmall-10-font-size:10px;
  --q-title-32-font-size:32px;

  --q-txt-18-font-size:18px;

  //font-style
  --q-font-style-italic: italic;

  //letter-spacing
  --q-letter-spacing-minus: -0.015em;
  --q-letter-spacing-25: 0.0025em;
  --q-letter-spacing-15: 0.0015em;
  --q-letter-spacing-5: 0.005em;
  --q-letter-spacing-1: 0.001em;
  --q-letter-spacing-125: 0.0125em;
  --q-letter-spacing-4: 0.004em;

  //text-decoration
  --q-text-uppercase: uppercase ;
  --q-text-lowercase: lowercase ;
  --q-text-capitalize: capitalize ;

  //line-height
  --q-line-height0: 0px;
  --q-line-height10: 10px;
  --q-line-height13: 13px;
  --q-line-height14: 14px;
  --q-line-height15: 15px;
  --q-line-height16: 16px;
  --q-line-height17: 17px;
  --q-line-height19: 19px;
  --q-line-height20: 20px;
  --q-line-height23: 23px;
  --q-line-height28: 28.13px;
  --q-line-height45: 45px;
}

body {
  font-family: var(--q-font-family);
}

.roboto{
  font-family: var(--q-font-family);
}

.montserrat{
  font-family: var(--q-secondary-font-family);
}

.italic{
  font-style: var(--q-font-style-italic);
}
// Text decoration
.text-decoration-none{
  text-decoration: none;
}
.text-decoration-underline{
  text-decoration: underline;
}

//Text Transform
.text-transform-initial{
  text-transform: initial;
}

//classes for font weight
.light-font-weight{
  font-weight: var(--q-font-weight-light);
}
.medium-font-weight{
  font-weight: var(--q-font-weight-medium);
}
.regular-font-weight{
  font-weight: var(--q-font-weight-regular);
}
.bold-font-weight{
  font-weight: var(--q-font-weight-bold);
}
.extrabold-font-weight{
  font-weight: var(--q-font-weight-extrabold);
}

//other classes for text decoration

.uppercase{
  text-transform: var(--q-text-uppercase);
}
.lowercase{
  text-transform: var(--q-text-lowercase);
}
.capitalize{
  text-transform: var(--q-text-capitalize);
}

//classes for header, typography
.h1{
  font-size: var(--q-font-size-h1);
  font-weight: var(--q-font-weight-regular);
  // letter-spacing: var(--q-letter-spacing-minus);
  // font-family: var(--q-secondary-font-family);
  font-family: var(--q-font-family);
}

.h1-M{
  font-size: var(--q-font-size-h1);
  font-weight: var(--q-font-weight-medium);
  letter-spacing: var(--q-letter-spacing-minus);
  // font-family: var(--q-secondary-font-family);
  font-family: var(--q-font-family);
}
.h1-Montserrat{
  font-size: var(--q-font-size-h1);
  font-weight: var(--q-font-weight-medium);
  font-family: var(--q-secondary-font-family);
}
.h1-Montserrat-regular{
  font-size: var(--q-font-size-h1);
  font-family: var(--q-secondary-font-family);
  font-weight: var(--q-font-weight-regular);
}
.h2{
  font-size: var(--q-font-size-h2);
  font-weight: var(--q-font-weight-medium);
  // font-family: var(--q-secondary-font-family);
  font-family: var(--q-font-family);
}
.h3{
  font-size: var(--q-font-size-h3);
  font-weight: var(--q-font-weight-regular);
  // font-family: var(--q-secondary-font-family);
  font-family: var(--q-font-family);
}
@mixin h3-Medium{
  font-size: var(--q-font-size-h3);
  font-weight: var(--q-font-weight-medium);
  // font-family: var(--q-secondary-font-family);
  
}
@mixin h3-bold{
  font-size: var(--q-font-size-h3);
  font-weight: var(--q-font-weight-bold);
  
}
.h3-Montserrat{
  @include h3-Medium;
  font-family: var(--q-secondary-font-family);
}
.h3-M{
  @include h3-Medium;
  font-family: var(--q-font-family);
}

.h3-M-secondary{
  @include h3-Medium;
  font-family: var(--q-secondary-font-family);
  @media (max-width: 767px){
    font-size: var(--q-large-20-font-size);
  }
}

.h3-Montserrat-mobile{
  @media (max-width: 767px){
   @include h3-Medium;
   font-family: var(--q-secondary-font-family);
  }
}


.h3-B{
@include h3-bold;
font-family: var(--q-secondary-font-family);
@media (max-width: 767px){
  font-size: var(--q-large-20-font-size);
}
}

.h3-success{
  @include h3-Medium;
  font-family: var(--q-secondary-font-family);
}

.h4{
  font-size: var(--q-font-size-h4);
  font-weight: var(--q-font-weight-large);
  // font-family: var(--q-secondary-font-family);
  font-family: var(--q-font-family);
}
.h4-M{
  font-size: var(--q-font-size-h4);
  font-weight: var(--q-font-weight-large);
  font-family: var(--q-secondary-font-family);
  
}
.h5{
  font-size: var(--q-font-size-h5);
  font-weight: var(--q-font-weight-bold);
  // font-family: var(--q-secondary-font-family);
  font-family: var(--q-font-family);
}
.h5-M{
  font-size: var(--q-font-size-h5);
  font-weight: var(--q-font-weight-bold);
  font-family: var(--q-secondary-font-family);
}
.h5-regular{
  font-size: var(--q-font-size-h5);
  font-weight: var(--q-font-weight-regular);
  font-family: var(--q-font-family);
}

.number-txt{
  font-size: var(--q-font-size-h4);
  font-weight:var(--q-font-weight-regular) ;
}

//body 
.bodyXXL-txt{
  font-size: var(--q-xxlarge-48-font-size);
  font-weight: var(--q-font-weight-regular);
  line-height: 56px;
}
.bodyXXL-txt-light{
  font-size: var(--q-xxlarge-48-font-size);
  font-weight: var(--q-font-weight-light);
  font-family: var(--q-secondary-font-family);
  line-height: 59px;
}

.bodyXL-txt{
  font-size: var(--q-xlarge-24-font-size);
  font-weight: var(--q-font-weight-regular);
  line-height: var(--q-line-height28);
}
.bodyXL-txt-italic{
  font-size: var(  --q-xlarge-24-font-size);
  font-weight: var(--q-font-weight-regular);
  font-style: var(--q-font-style-italic);
  line-height: var(--q-line-height28);
}
.bodyXL-txt-bold{
  font-size: var( --q-xlarge-24-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height28);
}
.bodyXL-txt-link{
  font-size: var(--q-xlarge-24-font-size);
  font-weight: var(--q-font-weight-regular);
  line-height: var(--q-line-height28);
  text-decoration:underline;
}
.bodyXL-txt-link-bold{
  font-size: var(--q-xlarge-24-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height28);
  text-decoration:underline;
}

@mixin bodyL-regular{
  font-size: var(--q-large-20-font-size) !important;
  font-weight: var(--q-font-weight-regular);
  line-height: var(--q-line-height23);
}

.bodyL-txt{
  @include bodyL-regular;
}
.bodyL-txt-italic{
  font-size: var(--q-large-20-font-size);
  font-weight: var(--q-font-weight-regular);
  font-style: var(--q-font-style-italic);
  line-height: var( --q-line-height23);
}

@mixin bodyL-large{
  font-size: var(--q-large-20-font-size) !important;
  font-weight: var(--q-font-weight-large);
  line-height: var(--q-line-height23);
}

.bodyL-txt-large{
  @include bodyL-large;
}

.bodyL-txt-medium{
  font-size: var(--q-large-20-font-size) !important;
  font-weight: var(--q-font-weight-medium);
  line-height: var(--q-line-height23);
}

@mixin bodyL-bold{
  font-size: var( --q-large-20-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var( --q-line-height23);
}

.bodyL-txt-bold{
  @include bodyL-bold;
}
.bodyL-txt-light{
  font-size: var( --q-large-20-font-size);
  font-weight: var(--q-font-weight-light);
  line-height: var( --q-line-height23);
}
.bodyL-txt-bold-italic{
  font-size: var( --q-large-20-font-size);
  font-weight: var(--q-font-weight-bold);
  font-style: var(--q-font-style-italic);
  line-height: var( --q-line-height23);
}
.bodyL-txt-link{
  font-size: var(--q-large-20-font-size);
  font-weight: var(--q-font-weight-regular);
  line-height: var(--q-line-height23);
  text-decoration:underline;
}
.bodyL-txt-link-bold{
  font-size: var(--q-large-20-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height23);
  text-decoration:underline;
}

.body-txt-18{
  font-size: var(--q-txt-18-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var( --q-line-height19);
}

@mixin bodyM-regular{
  font-size: var(--q-medium-16-font-size) !important;
  font-weight: var(--q-font-weight-regular);
  line-height: var( --q-line-height19);
}
@mixin bodyM-txt-medium{
  font-size: var(--q-medium-16-font-size);
  font-weight: var(--q-font-weight-medium);
  line-height: var( --q-line-height19);
}
.bodyM-txt{
  @include bodyM-regular;
}
.bodyM-txt-medium{
  font-size: var(--q-medium-16-font-size) !important;
  font-weight: var(--q-font-weight-medium);
  line-height: var( --q-line-height19);
}
.bodyM-txt-italic{
  font-size: var(--q-medium-16-font-size);
  font-weight: var(--q-font-weight-regular);
  font-style: var(--q-font-style-italic);
  line-height: var( --q-line-height19);
}
@mixin bodyM-bold{
  font-size: var(--q-medium-16-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var( --q-line-height19);
}
.bodyM-txt-bold{
  @include bodyM-bold;
}
.bodyM-txt-bold-italic{
  font-size: var(--q-medium-16-font-size);
  font-weight: var(--q-font-weight-bold);
  font-style: var(--q-font-style-italic);
  line-height: var( --q-line-height19);
}
.bodyM-txt-link{
  font-size: var(--q-medium-16-font-size);
  font-weight: var(--q-font-weight-regular);
  line-height: var(--q-line-height19);
  text-decoration:underline;
}
.bodyM-txt-link-bold{
  font-size: var(--q-medium-16-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height19);
  text-decoration:underline;
}

@mixin bodyS-regular{
  font-size: var(--q-small-14-font-size) !important;
  font-weight: var(--q-font-weight-regular);
  line-height: var( --q-line-height16);
}

.bodyS-txt{
  @include bodyS-regular;
}
.bodyS-txt-italic{
  font-size: var(--q-small-14-font-size);
  font-weight: var(--q-font-weight-regular);
  font-style: var(--q-font-style-italic);
  line-height: var( --q-line-height16);
}
.bodyS-txt-medium{
  font-size: var(--q-small-14-font-size);
  font-weight: var(--q-font-weight-medium);
  line-height: var( --q-line-height16);
}
.bodyS-txt-bold{
  font-size: var(--q-small-14-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var( --q-line-height16);
}
.bodyS-txt-large{
  font-size: var(--q-small-14-font-size);
  font-weight: var(--q-font-weight-large);
  line-height: var( --q-line-height17);
}
.bodyS-txt-bold-italic{
  font-size: var(--q-small-14-font-size);
  font-weight: var(--q-font-weight-bold);
  font-style: var(--q-font-style-italic);
  line-height: var( --q-line-height16);
}
.bodyS-txt-link{
  font-size: var( --q-small-14-font-size);
  font-weight: var(--q-font-weight-regular);
  line-height: var(--q-line-height16);
  text-decoration:underline;
}
.bodyS-txt-link-bold{
  font-size: var( --q-small-14-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height16);
  text-decoration:underline;
}

@mixin bodyXS-regular{
  font-size: var(--q-xsmall-12-font-size) !important;
  font-weight: var(--q-font-weight-regular);
  line-height: var( --q-line-height14);
}

.bodyXS-txt{
  @include bodyXS-regular;
}
.bodyXS-txt-italic{
  font-size: var(--q-xsmall-12-font-size);
  font-weight: var(--q-font-weight-regular);
  font-style: var(--q-font-style-italic);
  line-height: var( --q-line-height14);
}
.bodyXS-txt-bold{
  font-size: var(--q-xsmall-12-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var( --q-line-height14);
}
.bodyXS-txt-bold-italic{
  font-size: var(--q-xsmall-12-font-size);
  font-weight: var(--q-font-weight-bold);
  font-style: var(--q-font-style-italic);
  line-height: var( --q-line-height14);
}
.bodyXS-txt-link{
  font-size: var( --q-xsmall-12-font-size);
  font-weight: var(--q-font-weight-regular);
  line-height: var(--q-line-height14);
  text-decoration:underline;
}
.bodyXS-txt-link-bold{
  font-size: var( --q-xsmall-12-font-size);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height14);
  text-decoration:underline;
}
.title-32-font-size{
  font-size: var(--q-title-32-font-size);
  font-weight: var(--q-font-weight-light);
  line-height: 39px;
  font-family: var(--q-secondary-font-family);
}

.caption{
  font-size:  var(--q-xsmall-12-font-size);
 
  font-weight: var(--q-font-weight-regular);
  text-transform: var(--q-text-uppercase);
  font-family: var(--q-font-family);
}

.overline{
  font-size:  var(--q-xsmall-10-font-size);
  font-weight: var(--q-font-weight-regular);
  text-transform: var(--q-text-uppercase);
  font-family: var(--q-font-family);
}

.font16{
  font-size: var(--q-font-size-h5);
}
.font13{
  font-size: var(--q-small-13-font-size);
  line-height: var(--q-line-height14);
  font-weight: 500;
}
.font13-italic{
  font-size: var(--q-small-13-font-size);
  font-style: var(--q-font-style-italic);
  line-height: var(--q-line-height14);
  font-weight: var(--q-font-weight-light);
}
.font20{
  font-size: var(--q-large-20-font-size);
  font-weight: var(--q-font-weight-medium);
}
.font20-regular{
  font-size: var(--q-large-20-font-size);
  font-weight: var(--q-font-weight-regular);
}
.font13-italic-regular{
  font-size: var(--q-small-13-font-size);
  font-style: var(--q-font-style-italic);
  line-height: var(--q-line-height14);
  font-weight: var(--q-font-weight-regular);
}
.bodyM-txt-medium-mobile{
  @media (max-width: 767px){
    @include bodyM-txt-medium;
  }
}


//line height
.line-0{
  line-height: var(--q-line-height0);
}
.line-10{
  line-height: var(--q-line-height10);
}
.line-13{
  line-height: var(--q-line-height13);
}
.line-15{
  line-height: var(--q-line-height15);
}
.line-16{
  line-height: var(--q-line-height16);
}
.line-17{
  line-height: var(--q-line-height17);
}
.line-20{
  line-height: var(--q-line-height20);
}
.line-23{
  line-height: var(--q-line-height23);
}
.line-28{
  line-height: var(--q-line-height28);
}
.line-45{
  line-height: var(--q-line-height45);
}



// Mobile view css 

.h3-M-mobile{
  @media (max-width: 767px){
   @include h3-Medium;
   font-family: var(--q-font-family);
  }
}


.h4-L-mobile{
  @media (max-width: 767px){
    font-size: var(--q-font-size-h4);
    font-weight: var(--q-font-weight-large);
    font-family: var(--q-font-family);

    &:focus{
      font-size: 20px;
    }
  }
}

.h4-L-success-mobile{
  @media (max-width: 767px){
    font-size: var(--q-font-size-h4);
    font-weight: var(--q-font-weight-large);
    font-family: var(--q-secondary-font-family);
  }
}

.bodyL-txt-mobile{
  @media (max-width: 767px){
    @include bodyL-regular();
  }
}

.bodyL-txt-large-mobile{
  @media (max-width: 767px){
    @include bodyL-large;
  }
}

.bodyL-txt-bold-mobile{
  @media (max-width: 767px){
    @include bodyL-bold;
  }
}

.bodyM-txt-bold-mobile{
  @media (max-width: 767px){
    @include bodyM-bold;
  }
}

.bodyM-txt-mobile{
  @media (max-width: 767px) {
    @include bodyM-regular;
  }
}

.bodyXS-txt-mobile{
  @media (max-width: 767px) {
    @include bodyXS-regular;
  }
}

.bodyS-txt-Mobile{
  @media (max-width: 767px) {
    @include bodyS-regular;
  }
}

.tagline{
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}