.form-text-input{
    ::placeholder{
        font-style: italic;
        font-size: 1.4rem;
        font-weight: 300;
        color: #9DAAB0;
        
    }
}

kendo-combobox, kendo-textarea {
  ::placeholder{
    font-style: italic;
    font-size: 1.4rem;
    font-weight: 300;
    color: #9DAAB0;
  }
}
::placeholder{
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    color: #9DAAB0;
}
.patient_schedule_main,
.schedule_appointment_time_main{
    ::placeholder{
        font-style: italic !important;
        font-size: 1.4rem !important;
        font-weight: 400;
        color:#738289 !important;
    }
}
.clinic_signin_form_main{
    ::placeholder{
        font-style: normal !important;
        font-size: 1.4rem !important;
        font-weight: 400;
    }
}
.confirm_patient_popup{
    ::placeholder{
        font-style: normal !important;
        font-size: 1.4rem !important;
        font-weight: 400;
    }
}


.text-ellipse {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    // width: 100% !important;
    // max-width: 300px !important;
    display: block !important;
}

/* width */
::-webkit-scrollbar {
    height: 5px !important;
  }

  ::ng-deep{
    .refered_provider_grid{
        .k-pager-numbers{
            display: none !important;
       }
    }
    
}

.k-input-solid:focus-within{
     border-color: var(--ion-color-highlight);
}
.k-input-solid.ng-invalid.ng-dirty{
    border-color: #f31700;
}
::ng-deep{
    .k-input-md .k-input-inner, .k-picker-md .k-input-inner{
        padding-block: 8px;
        padding-inline: 8px;
    }
}
kendo-dropdownlist,
kendo-textbox,
kendo-datepicker,
kendo-multiselect,
kendo-combobox, kendo-numerictextbox, kendo-multicolumncombobox,kendo-textarea {
  ::placeholder{
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    color: #9DAAB0;
  }
}